import { User } from 'store/modules/users'
import { BaseModel } from 'store/utils/base-model'

export abstract class BasePolicy<Type extends BaseModel> {
  constructor(protected user: User, protected object: Type) {}
}

export type PolicyClass<Type extends BaseModel = never> = new (
  user: User,
  object: Type
) => BasePolicy<Type>
